/**
 *  Разбивает строку на спаны по словам
 *
 *  Пример использования:
 *
 *  Ширина контейнера (наружного элемента) должна быть зафиксирована (display: block, flex: 0 0 200px, ...)
 *  иначе на разных устройствах текст будет переносится по разному.
 *
 *  Обязательно к проверке на Android / iOS!
 *
 *  <div class="title">
 *      <div class="title__inner" data-word-split>
 *         Достаточно длинный текст
 *      </div>
 *  </div>
 * */

import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

class WordSplit {
    container = undefined;
    element = undefined;
    classes = [];
    originalString = undefined;
    singleLineTestElement = undefined;
    words = [];
    line = undefined;

    constructor(selector) {
        this.element = selector;
        this.container = this.element.parentElement;
        this.classes = this.element.classList;
        this.originalString = this.element.innerHTML.trim();
        this.addContainerStyles();
        this.words = this.originalString.split(' ');
        this.element.remove();
        this.create();
    }

    addContainerStyles() {
        this.container.classList.add('_word-split-container');
        this.container.style.position = 'relative';
        this.container.style.display = 'flex';
        this.container.style.flexWrap = 'wrap';
    }

    create() {
        this.words.forEach((word, index) => {
            if (index < this.words.length - 1) {
                this.createNewElement(word + '&nbsp;');
            } else {
                this.createNewElement(word);
            }
        });
    }

    createNewElement(word) {
        const newElement = document.createElement('span');
        const newElementInner = document.createElement('span');
        newElement.append(newElementInner);
        this.classes.forEach(className => newElement.classList.add(className));
        if (this.element.dataset.textInnerClass) {
            newElementInner.className = this.element.dataset.textInnerClass;
        }
        newElement.style.display = 'block';
        newElementInner.style.display = 'block';
        newElementInner.innerHTML = word;
        this.container.append(newElement);
    }
}

function init() {
    document.querySelectorAll('[data-word-split]:not([data-initialized="true"])')
        .forEach((item) => {
            item.dataset.initialized = 'true';
            const textWrapCreator = new WordSplit(item);
        });
}

onDomReady(() => init());
onDomChanges(() => init());