import RSlider from "../../components/rslider/rslider";
import {onDomReady} from "../../components/dynamic/observer";

class ModalProductSlider {
    constructor() {
        this.modal = document.querySelector('[data-modal-content="product"]');
        this.rsliderElement = document.querySelector('[data-rslider="modal-product-slider"]');
        if (!this.rsliderElement) {
            console.log('ModalProductSlider: Could not found rslider element');
            return;
        }

        this.thumbsContainer = document.querySelector('[data-modal-product-slider-thumbs]');
        this.thumbsItems = Array.from(this.thumbsContainer.querySelectorAll('[data-modal-product-slider-thumb]'));

        this.init();
        this.eventListeners();
    }

    init() {
        this.initSlider();
        this.initThumbs();
    }

    initSlider() {
        this.slider = new RSlider(
            this.rsliderElement,
            {
                swipe: false,
                onStart: ({ slideIn, slideFriendsIn }) => {
                    slideFriendsIn.forEach((friendIn) => {
                        friendIn.classList.add('_active');
                    })
                    slideIn.classList.add('_active');
                },
                timeline: [
                    RSlider.action({
                        name: 'next-act',
                        duration: 400,
                        delay: 0,
                        onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            const slideInIndex = parseInt(slideIn.dataset.rslide, 10);
                            this.thumbsItems.map((thumb, index) => {
                                if (index + 1 === slideInIndex) {
                                    this.setActiveThumb(thumb);
                                }
                            });

                            slideIn.classList.add('_active');
                            slideOut.classList.remove('_active');

                            slideIn.classList.add('_in-next-act');
                            slideOut.classList.add('_out-next-act');

                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.add('_active');
                                friendIn.classList.add('_in-next-act');
                            })

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.remove('_active');
                                friendOut.classList.add('_out-next-act');
                            })
                        },
                        onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            slideIn.classList.remove('_in-next-act');
                            slideOut.classList.remove('_out-next-act');

                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.remove('_in-next-act');
                            })

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.remove('_out-next-act');
                            })

                            slideOut.scrollTop = 0;
                        }
                    }),
                ],
                timelineReverse: [
                    RSlider.action({
                        name: 'next-act',
                        duration: 400,
                        delay: 0,
                        onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            const slideInIndex = parseInt(slideIn.dataset.rslide, 10);
                            this.thumbsItems.map((thumb, index) => {
                                if (index + 1 === slideInIndex) {
                                    this.setActiveThumb(thumb);
                                }
                            });

                            slideIn.classList.add('_active');
                            slideOut.classList.remove('_active');

                            slideIn.classList.add('_in-next-act');
                            slideOut.classList.add('_out-next-act');

                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.add('_active');
                                friendIn.classList.add('_in-next-act');
                            })

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.remove('_active');
                                friendOut.classList.add('_out-next-act');
                            })
                        },
                        onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            slideIn.classList.remove('_in-next-act');
                            slideOut.classList.remove('_out-next-act');

                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.remove('_in-next-act');
                            })

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.remove('_out-next-act');
                            })

                            slideOut.scrollTop = 0;
                        }
                    }),
                ],
            });
    }

    initThumbs() {
        this.thumbsItems.forEach((thumb, index) => {
            thumb.addEventListener('click', () => {
                if (!this.slider.playTimestamp && this.slider.currentIndex !== index) {
                    this.setActiveThumb(thumb);
                    this.slider.toSlide(index);
                }
            });
        });
    }

    setActiveThumb(thumb) {
        this.thumbsItems.map(thumb => thumb.classList.remove('_active'));
        thumb.classList.add('_active');
    }

    eventListeners() {
        this.modal.addEventListener('ModalContentOpened', (e) => {
            const slideIndex = e.detail.slideId - 1;
            if (this.slider.currentIndex !== slideIndex) {
                this.slider.toSlide(slideIndex);
            }
        });
    }
}

onDomReady(() => {
    const modalProductSlider = new ModalProductSlider();
})