import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper, Pagination, FreeMode} from 'swiper';

function init() {
    const openSystemSlider = document.querySelector('[data-open-system-slider]');
    if (!openSystemSlider) {
        return;
    }

    const leftArrow = document.querySelector('[data-open-system-slider-left]');
    const rightArrow = document.querySelector('[data-open-system-slider-right]');
    const progressbar = document.querySelector('[data-open-system-progressbar]');

    const slider = new Swiper(openSystemSlider, {
        modules: [Navigation, Pagination, FreeMode],
        slidesPerView: "auto",
        freeMode: true,
        grabCursor: true,
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
        pagination: {
            el: progressbar,
            type: "progressbar",
        },
        breakpoints: {
            0: {
                spaceBetween: 10,
                freeMode: false,
            },
            768: {
                spaceBetween: 20,
                freeMode: false,
            },
            1180: {
                spaceBetween: 30,
            },
        }
    });
}

onDomReady(() => init());