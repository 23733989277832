export default class ProductSliderObject {
    offsetY = 0;
    activeIndex = 0;
    textActiveIndex = 0;
    ease = 0.15;
    constructor(container) {
        this.container = container;
        this.slides = this.container.querySelectorAll('[data-product-slide]');

        if (!this.slides.length) {
            console.log('SliderObject: could not found slides.');
            return false;
        }

        this.textSlides = Array.from(this.container.querySelectorAll('[data-parallax-slide-text]'));
        if (!this.textSlides.length) {
            console.log('SliderObject: could not found text slides.');
        }

        this.paginationSlides = Array.from(this.container.querySelectorAll('[data-parallax-slide-pagination]'));
        this.paginationProgressBar =  this.container.querySelector('[data-parallax-pagination-progress]');

        const rootStyles = getComputedStyle(document.documentElement);
        this.headerHeight = parseInt(rootStyles.getPropertyValue('--header-height'), 10);

        this.slideHeight = window.innerHeight - this.headerHeight;

        this.container.style.height = `${this.slides.length * this.slideHeight * 1.5}px`;
    }

    computedOffset() {
        const bounds = this.container.getBoundingClientRect();
        this.offsetY = (1 - this.ease) * this.offsetY + this.ease * (((bounds.top - this.headerHeight) * -1) / 1.5);
    }

    changeSlidesByOffset() {
        if (this.offsetY > 0 && this.offsetY < this.slides.length * this.slideHeight) {
            this.activeIndex = Math.floor(this.offsetY / this.slideHeight);
            if (this.activeIndex !== this.slides.length - 1) {
                this.slides[this.activeIndex].style.height = `${this.slideHeight * (this.activeIndex + 1) - this.offsetY}px`;
            }

            if (this.activeIndex > 0) {
                this.slides[this.activeIndex - 1].style.height = '0';
            } else if (this.activeIndex < this.slides.length - 1) {
                this.slides[this.activeIndex + 1].style.height = `${this.slideHeight * (this.activeIndex + 1)}px`;
            }

            this.textActiveIndex = Math.floor((this.offsetY + window.innerHeight / 3) / this.slideHeight);
            if (this.textActiveIndex <= this.slides.length - 1) {
                this.textSlides.map(item => {
                    if (item.classList.contains('_active')) {
                        item.classList.remove('_active');
                    }
                });
                if (!this.textSlides[this.textActiveIndex].classList.contains('_active')) {
                    this.textSlides[this.textActiveIndex].classList.add('_active');
                }

                this.paginationSlides.map(item => {
                    if (item.classList.contains('_active')) {
                        item.classList.remove('_active');
                    }
                });
                if (!this.paginationSlides[this.textActiveIndex].classList.contains('_active')) {
                    this.paginationSlides[this.textActiveIndex].classList.add('_active');
                }
            }

            const fillPercent = Math.round((this.offsetY / ((this.slides.length - 1) * this.slideHeight)) * 100);
            if (fillPercent > 0 && fillPercent <= 100) {
                this.paginationProgressBar.style.width = `${fillPercent}%`;
            }
        }
    }

    update() {
        this.computedOffset();
        this.changeSlidesByOffset();
    }

    clearStyles() {
        this.container.style.height = '';
        this.slides.forEach((slide) => {
            slide.style.height = '';
        })
    }
}