import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";
import {validateYandexCaptcha} from "../captcha/yandex-captcha-helper";

/**
 *
 *  Локальные модалочки
 *
 */

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  let link = this;
  let lastWindowPosition = 0;
  link.style.pointerEvents = 'none';
  let modal = new Modal(this, {
    animation: {
      closeTimeout: 400,
    },
    closeOnSuccessDelay: this.dataset.closeOnSuccessDelay ?? 3000,
    closerText: '',
    onAfterOpen: function () {
      if (window.innerWidth < 1180) {
        lastWindowPosition = window.scrollY;

        setTimeout(() => {
          document.querySelector('.layout').style.position = 'relative';
          document.querySelector('.layout').style.overflow = 'hidden';
          document.querySelector('.layout').style.height = 'calc(var(--vh, 1vh) * 100)';
        }, 400);
      }
    },
    onBeforeClose: function () {
    },
    onAfterClose: () => {
      if (window.innerWidth < 1180) {
        document.querySelector('.layout').style.position = '';
        document.querySelector('.layout').style.overflow = '';
        document.querySelector('.layout').style.height = '';
        window.scroll(0, lastWindowPosition);
      }

      this.style.pointerEvents = '';
    },
    onFormSuccess: function () {
      const submitEvent = new Event('modal-form:success');
      submitEvent.element = link;
      document.dispatchEvent(submitEvent);

      const successElement = this.content.querySelector('[data-modal-success]');
      if (successElement) {
        successElement.classList.remove('need-animation');
      }
    },
    async onSubmit(form) {
      try {
        await validateYandexCaptcha(form);
      } catch (e) {
        console.log(e);
      }
      this.onSubmitDefault(form);
    },
  });
  return false;
});