export default class PhoneObject {
    whiteBlocks = [];
    isBackgroundWhite = false;

    constructor(selector) {
        this.element = selector;

        this.collectWhiteBlocks();

        this.hideBlock = document.querySelector('[data-hide-block]');
    }

    collectWhiteBlocks() {
        this.whiteBlocks = [];
        this.whiteBlocks = Array.from(document.querySelectorAll('[data-white-block]'));
    }

    show() {
        this.element.classList.add('_visible');
    }

    hide() {
        this.element.classList.remove('_visible');
    }

    toWhite() {
        this.element.classList.add('_white');
        this.element.classList.remove('_black');
    }

    toBlack() {
        this.element.classList.add('_black');
        this.element.classList.remove('_white');
    }

    update(offsetY) {
        const phoneObjectMiddle = (window.innerHeight / 100) * 30;
        const isWhiteBlock = this.whiteBlocks.some((item) => {
            const itemBounds = item.getBoundingClientRect();
            const top = itemBounds.top;
            const bottom = itemBounds.bottom;
            if (phoneObjectMiddle > top && phoneObjectMiddle < bottom) {
                return item;
            }
        });

        if (isWhiteBlock) {
            if (!this.isBackgroundWhite) {
                this.toBlack();
                this.isBackgroundWhite = true;
            }
        } else {
            if (this.isBackgroundWhite) {
                this.toWhite();
                this.isBackgroundWhite = false;
            }
        }

        const hideBlockTop = this.hideBlock.getBoundingClientRect().top;
        if (offsetY < (window.innerHeight / 100) * 70) {
            this.hide();
        } else if (phoneObjectMiddle > hideBlockTop) {
            this.hide();
        } else {
            this.show();
        }
    }
}