function isEnabledYandexCaptchaForForm(form) {
  return window.smartCaptcha
    && form.dataset.yandexCaptcha
    && form.querySelector('[data-captcha-container]')
    && form.querySelector('[data-captcha-container]').dataset.containerId;
}

function validateYandexCaptcha(form) {
  if (!isEnabledYandexCaptchaForForm(form)) {
    return new Promise((resolve, reject) => resolve(true));
  }
  const containerEl = form.querySelector('[data-captcha-container]');
  const containerId = containerEl.dataset.containerId;
  return new Promise((resolve, reject) => {
    window.smartCaptcha.subscribe(containerId, 'success', (token) => {
      return resolve(true);
    });
    window.smartCaptcha.subscribe(containerId, 'network-error', (token) => {
      return reject('network-error');
    });
    window.smartCaptcha.subscribe(containerId, 'javascript-error', (token) => {
      return reject('javascript-error');
    });
    window.smartCaptcha.execute(containerId);
  })
}

export {
  isEnabledYandexCaptchaForForm,
  validateYandexCaptcha
}