export default class CaseSliderObject {
    ease = 0.3;
    offset = 0;
    translate = 0;
    constructor(selector) {
        this.element = selector;

        const rootStyles = getComputedStyle(document.documentElement);
        this.headerHeight = parseInt(rootStyles.getPropertyValue('--header-height'), 10);
    }

    computedTranslate() {
        const elementTop = this.element.getBoundingClientRect().top;
        if (elementTop > window.innerHeight) {
            this.translate = (window.innerHeight - this.headerHeight) / 2;
        } else if (elementTop < this.headerHeight) {
            this.translate = 0;
        } else {
            this.translate = (1 - this.ease) * this.translate + this.ease * ((elementTop - this.headerHeight) / 2);
        }
    }

    setStyles() {
        this.element.style.transform = `translate3d(0, -${this.translate}px, 0)`;
    }

    update() {
        this.computedTranslate();
        this.setStyles();
    }

    clearStyles() {
        this.element.style.transform = '';
    }
}