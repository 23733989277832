import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper, Pagination, FreeMode, Controller} from 'swiper';

function init() {
    const sliderElement = document.querySelector('[data-case-slider]');
    if (!sliderElement) {
        return;
    }

    const counterEl = document.querySelector('[data-case-slider-counter]');
    const counterSlider = new Swiper(counterEl, {
        allowTouchMove: false,
        direction: 'vertical',
        preventInteractionOnTransition: true,
    });

    const prevEl = document.querySelector('[data-case-slider-prev]');
    const nextEl = document.querySelector('[data-case-slider-next]');
    const progressbar = document.querySelector('[data-case-slider-progressbar]');

    const slider = new Swiper(sliderElement, {
        modules: [Navigation, Pagination, FreeMode, Controller],
        slidesPerView: "auto",
        freeMode: true,
        grabCursor: true,
        speed: 1000,
        navigation: {
            prevEl: prevEl,
            nextEl: nextEl,
        },
        controller: {
            control: counterSlider
        },
        pagination: {
            el: progressbar,
            type: "progressbar",
        },
        breakpoints: {
            0: {
                spaceBetween: 10,
            },
            768: {
                spaceBetween: 20,
            },
            1180: {
                spaceBetween: 30,
            },
        },
    });
}

onDomReady(() => init());