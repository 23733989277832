export default class ProductPictureObject {
    ease = 0.5;
    offset = 0;
    translate = 0;
    constructor(container, picture) {
        this.container = container;
        this.element = picture;

        const rootStyles = getComputedStyle(document.documentElement);
        this.headerHeight = parseInt(rootStyles.getPropertyValue('--header-height'), 10);
    }

    computedTranslate() {
        const containerTop = this.container.getBoundingClientRect().top;
        if (containerTop > window.innerHeight * 3) {
            this.translate = (1 - this.ease) * this.translate + this.ease * ((window.innerHeight - this.headerHeight) / 4);
        } else if (containerTop < this.headerHeight) {
            this.translate = 0;
        } else {
            this.translate = (1 - this.ease) * this.translate + this.ease * ((containerTop - this.headerHeight) / 4);
        }
    }

    setStyles() {
        this.element.style.transform = `translate3d(0, -${this.translate}px, 0)`;
    }

    update() {
        this.computedTranslate();
        this.setStyles();
    }

    clearStyles() {
        this.element.style.transform = '';
    }
}