import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper} from "swiper";

class TeamSlider {
    isLoading = false;
    lastWindowInnerWidth = window.innerWidth;
    constructor() {
        this.initImageSlider();
        this.initTitleSlider();
        this.initJobTitleSlider();
        this.bind();
        this.eventListeners();
    }

    initImageSlider() {
        const sliderEl = document.querySelector('[data-team-slider]');

        if (sliderEl) {
            const prevEl = document.querySelector('[data-team-slider-prev]');
            const nextEl = document.querySelector('[data-team-slider-next]');

            this.imageSlider = new Swiper(sliderEl, {
                modules: [Navigation],
                speed: 400,
                slidesPerView: "auto",
                grabCursor: true,
                loop: true,
                navigation: {
                    prevEl: prevEl,
                    nextEl: nextEl,
                },
                breakpoints: {
                    0: {
                        spaceBetween: 10,
                    },
                    768: {
                        spaceBetween: 20,
                    },
                    1180: {
                        spaceBetween: 30,
                    },
                },
                on: {
                    beforeInit(swiper) {
                        function appendItemToList(index, slides) {
                            const newItem = slides[index].cloneNode(true);
                            swiper.$wrapperEl[0].append(newItem);
                        }

                        const originalSlides = swiper.$wrapperEl[0].querySelectorAll('.swiper-slide');
                        if (originalSlides.length < 4) {
                            let index = 0;
                            while (swiper.$wrapperEl[0].querySelectorAll('.swiper-slide').length < 4) {
                                if (index === originalSlides.length - 1) {
                                    index = 0;
                                }
                                appendItemToList(index, originalSlides);
                                index++;
                            }
                        }
                    }
                }
            });
        }
    }

    initTitleSlider() {
        const sliderTitleEl = document.querySelector('[data-team-title-slider]');
        if (sliderTitleEl) {
            this.sliderTitle = new Swiper(sliderTitleEl, {
                slidesPerView: 1,
                speed: 400,
                rewind: true,
                allowTouchMove: false,
            });
        }
    }

    initJobTitleSlider() {
        const sliderJobTitleEl = document.querySelector('[data-team-job-title-slider]');
        if (sliderJobTitleEl) {
            this.sliderJobTitle = new Swiper(sliderJobTitleEl, {
                slidesPerView: 1,
                speed: 400,
                rewind: true,
                allowTouchMove: false,
                on: {
                    init: (swiper) => {
                        let maxSlideHeight = 0;
                        swiper.slides.forEach((slide) => {
                            if (slide.offsetHeight > maxSlideHeight) {
                                maxSlideHeight = slide.offsetHeight;
                            }
                        });

                        if (maxSlideHeight !== 0) {
                            swiper.$wrapperEl[0].style.height = `${maxSlideHeight}px`;
                        }
                    }
                }
            });
        }
    }

    bind() {
        if (this.imageSlider) {
            this.imageSlider.on('slidePrevTransitionEnd', this.slidePrevTransitionEndHandler);
            this.imageSlider.on('slideNextTransitionEnd', this.slideNextTransitionEndHandler);
            this.imageSlider.on('activeIndexChange', this.titleSlideHandler.bind(this));
            // this.imageSlider.on('slidePrevTransitionStart', this.slidePrevTransitionStartHandler.bind(this));
            // this.imageSlider.on('slideNextTransitionStart', this.slideNextTransitionStartHandler.bind(this));
        }
    }

    slidePrevTransitionEndHandler(swiper) {
        if (swiper.activeIndex === swiper.loopedSlides - 1) {
            swiper.$el.addClass('_animation-off');
            swiper.slideTo(swiper.loopedSlides * 2 - 1, 0, false);
            setTimeout(() => {
                swiper.$el.removeClass('_animation-off');
            });
        }
    }

    slideNextTransitionEndHandler(swiper) {
        if (swiper.activeIndex === swiper.slides.length - swiper.loopedSlides) {
            swiper.$el.addClass('_animation-off');
            swiper.slideTo(swiper.loopedSlides, 0, false);
            setTimeout(() => {
                swiper.$el.removeClass('_animation-off');
            });
        }
    }

    titleSlideHandler(swiper) {
        if (this.sliderTitle) {
            if (swiper.activeIndex === swiper.loopedSlides - 1) {
                this.sliderTitle.slideTo(this.sliderTitle.slides.length, 400, false);
            } else if (swiper.activeIndex === swiper.slides.length - swiper.loopedSlides) {
                this.sliderTitle.slideTo(0, 400, false);
            } else {
                this.sliderTitle.slideTo(Math.abs(swiper.loopedSlides - swiper.activeIndex), 400, false);
            }
        }

        if (this.sliderJobTitle) {
            if (swiper.activeIndex === swiper.loopedSlides - 1) {
                this.sliderJobTitle.slideTo(this.sliderTitle.slides.length, 400, false);
            } else if (swiper.activeIndex === swiper.slides.length - swiper.loopedSlides) {
                this.sliderJobTitle.slideTo(0, 400, false);
            } else {
                this.sliderJobTitle.slideTo(Math.abs(swiper.loopedSlides - swiper.activeIndex), 400, false);
            }
        }
    }

    slidePrevTransitionStartHandler() {
        if (this.sliderTitle) {
            this.sliderTitle.slidePrev(400, false);
        }

        if (this.sliderJobTitle) {
            this.sliderJobTitle.slidePrev(400, false);
        }
    }

    slideNextTransitionStartHandler() {
        if (this.sliderTitle) {
            this.sliderTitle.slideNext(400, false);
        }

        if (this.sliderJobTitle) {
            this.sliderJobTitle.slideNext(400, false);
        }
    }

    eventListeners() {
        window.addEventListener('resize', (e) => {
            if (e.target.innerWidth === this.lastWindowInnerWidth) {
                return;
            }

            this.lastWindowInnerWidth = e.target.innerWidth;

            if (this.isLoading) {
                return;
            }
            this.isLoading = true;

            this.imageSlider.off('slidePrevTransitionEnd', this.slidePrevTransitionEndHandler);
            this.imageSlider.off('slideNextTransitionEnd', this.slideNextTransitionEndHandler);
            this.imageSlider.off('slidePrevTransitionStart', this.slidePrevTransitionStartHandler);
            this.imageSlider.off('slideNextTransitionStart', this.slideNextTransitionStartHandler);

            this.imageSlider.destroy();
            this.sliderTitle.destroy();
            this.sliderJobTitle.destroy();

            setTimeout(() => {
                this.initImageSlider();
                this.initTitleSlider();
                this.initJobTitleSlider();
                this.bind();

                this.isLoading = false;
            }, 1000);
        });
    }
}
onDomReady(() => {
    const teamSlider = new TeamSlider();
});