import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

class Follower {
    limits = undefined;
    ease = 0.05;
    observeAreas = [];
    images = [];
    cursor = {
        x: 0,
        y: 0,
    };
    currentImagesPosition = {
        x: 0,
        y: 0,
    };
    lastImagesPosition = {
        x: 0,
        y: 0,
    };

    constructor(selector) {
        this.container = selector;

        if (selector.dataset.followerContainer) {
            this.limits = JSON.parse(selector.dataset.followerContainer);
        }

        this.imagesContainer = selector.querySelector('[data-follow-picture-list]');
        this.images = Array.from(this.imagesContainer.querySelectorAll('[data-follow-picture-item]'));

        this.imagesContainerSizes = {
            width: this.imagesContainer.offsetWidth,
            height: this.imagesContainer.offsetHeight
        };

        this.observeAreas = Array.from(this.container.querySelectorAll('[data-follow-item]'));
        if (!this.observeAreas.length) {
            return;
        }

        this.eventListeners();
        this.update();
    }

    update() {
        if (!this.imagesContainer || this.images.length === 0) {
            return false;
        }

        const bounds = this.container.getBoundingClientRect();
        if (bounds.top > window.innerHeight) {
            this.isObservable = false;
        } else if (bounds.bottom < 0) {
            this.isObservable = false;
        } else {
            this.isObservable = true;
        }

        if (this.isObservable) {
            this.setStyles();
        }

        window.requestAnimationFrame(this.update.bind(this));
    }

    computedX() {
        if (this.item) {
            this.lastImagesPosition.x = this.cursor.x - this.imagesContainerSizes.width / 2;

            const boundingItem = this.item.getBoundingClientRect();

            if (this.limits) {
                if (this.cursor.x < (boundingItem.left + (boundingItem.width * this.limits.left))) {
                    this.lastImagesPosition.x = boundingItem.left + (boundingItem.width * this.limits.left) - this.imagesContainerSizes.width / 2;
                } else if (this.cursor.x > (boundingItem.left + (boundingItem.width * this.limits.right))) {
                    this.lastImagesPosition.x = boundingItem.left + (boundingItem.width * this.limits.right) - this.imagesContainerSizes.width / 2;
                } else {
                    this.lastImagesPosition.x = this.cursor.x - this.imagesContainerSizes.width / 2;
                }
            } else {
                this.lastImagesPosition.x = this.cursor.x - this.imagesContainerSizes.width / 2;
            }
        } else {
            const boundingItem = this.observeAreas[0].getBoundingClientRect();
            this.lastImagesPosition.x = boundingItem.left + (boundingItem.width * this.limits.left) - this.imagesContainerSizes.width / 2;
        }
    }

    computedY() {
        this.lastImagesPosition.y = this.cursor.y - this.imagesContainerSizes.height / 2;
    }

    lerp() {
        this.currentImagesPosition.x = (1 - this.ease) * this.currentImagesPosition.x + this.ease * this.lastImagesPosition.x;
        this.currentImagesPosition.y = (1 - this.ease) * this.currentImagesPosition.y + this.ease * this.lastImagesPosition.y;
    }

    setImageX() {
        this.imagesContainer.style.left = `${this.currentImagesPosition.x}px`;
    }

    setImageY() {
        this.imagesContainer.style.top = `${this.currentImagesPosition.y}px`;
    }

    setStyles() {
        this.lerp();
        this.setImageY();
        this.setImageX();
    }

    eventListeners() {
        window.addEventListener('mousemove', (e) => {
            this.cursor.x = e.clientX;
            this.cursor.y = e.clientY;
            this.computedY();
            this.computedX();
        });

        window.addEventListener('resize', (e) => {
            const bounding = this.imagesContainer.getBoundingClientRect();
            this.imagesContainerSizes.width = bounding.width;
            this.imagesContainerSizes.height = bounding.height;
        });

        this.observeAreas.forEach((item) => {
            const currentImage = this.images.find((image) => image.dataset.followPictureItem === item.dataset.followItem);
            if (currentImage) {
                item.addEventListener('mouseenter', () => {
                    this.images.map(image => {
                        image.classList.remove('_visible');
                        image.style.zIndex = '5';
                    });
                    currentImage.classList.add('_visible');
                    currentImage.style.zIndex = '10';

                    this.item = item;
                });
            }
        });

        this.container.addEventListener('mouseenter', () => {
            this.imagesContainer.classList.add('_visible');
        });

        this.container.addEventListener('mouseleave', () => {
            this.imagesContainer.classList.remove('_visible');
            this.images.map(item => item.classList.remove('_visible'));
            this.item = undefined;
        });
    }
}

function init() {
    if (window.innerWidth >= 1180) {
        const containers = document.querySelectorAll('[data-follower-container]:not([data-initialize="true"])');
        containers.forEach((item) => {
            item.dataset.initialize = "true";
            const follower = new Follower(item);
        });
    }
}

onDomReady(() => init());
onDomChanges(() => init());