import RSlider from "../../components/rslider/rslider";
import {onDomReady} from "../../components/dynamic/observer";

onDomReady(() => {
  const rsliderElement = document.querySelector('[data-rslider="rslider-test"]');
  if (!rsliderElement) {
    return;
  }

  const slider = new RSlider(
      rsliderElement,
      {
        onStart: ({ slideIn, slideFriendsIn }) => {
          slideFriendsIn.forEach((friendIn) => {
            friendIn.classList.add('_active');
          })
          slideIn.classList.add('_active');
        },
        timeline: [
          RSlider.action({
            // К слайдам докидывается:
            // К появляющемуся: --rslider-first-act-in-progress: 0.2;
            // К исчезающему: --rslider-first-act-out-progress: 0.2;
            // easing ниже настраивается
            name: 'first-act',
            duration: 2000,
            delay: 0,
            // easeInOutCubic прогресс для "приходящего" слайда
            // easingIn: (x) => x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2,
            // linear прогресс для "уходящего" слайда
            // easingOut: (x) => x,
            onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
              slideIn.classList.add('_active');
              slideOut.classList.remove('_active');

              slideIn.classList.add('_in-first-act');
              slideOut.classList.add('_out-first-act');

              slideFriendsIn.forEach((friendIn) => {
                friendIn.classList.add('_in-first-act');
              })

              slideFriendsOut.forEach((friendOut) => {
                friendOut.classList.add('_out-first-act');
              })
            },
            onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
              slideIn.classList.remove('_in-first-act');
              slideOut.classList.remove('_out-first-act');

              slideFriendsIn.forEach((friendIn) => {
                friendIn.classList.remove('_in-first-act');
              })

              slideFriendsOut.forEach((friendOut) => {
                friendOut.classList.remove('_out-first-act');
              })
            }
          }),
        ]
      });
})