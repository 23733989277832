import {onDomReady} from '../../components/dynamic/observer';
import ImageObject from './objects/ImageObject';
import PhoneObject from "./objects/PhoneObject";
import HeroObject from "./objects/HeroObject";
import CaseSliderObject from "./objects/CaseSliderObject";
import ProductPictureObject from "./objects/ProductPictureObject";
import ProductSliderObject from "./objects/ProductSliderObject";

class Parallax {
    offsetY = 0;
    maxMediumWidth = 1179;

    constructor() {
        if (window.innerWidth > this.maxMediumWidth) {
            this.initObjects();
            this.eventListeners();
            this.update();
        }
    }

    initObjects() {
        this.initHeroObject();
        this.initImageObjects();
        this.initPhoneObject();
        this.initProductSliderObject();
        this.initProductSliderPictureObject();
        this.initCaseSliderObject();
    }

    initImageObjects() {
      this.imageObjects = [];
      Array.from(document.querySelectorAll('[data-parallax-image-container]'))
          .forEach((container) => {
            this.imageObjects.push(new ImageObject(container));
          });
    }

    initHeroObject() {
        if (document.querySelector('[data-hero]')) {
            this.heroObject = new HeroObject(document.querySelector('[data-hero]'));
        }
    }

    initPhoneObject() {
        if (document.querySelector('[data-sticky-phone]')) {
            this.phoneObject = new PhoneObject(document.querySelector('[data-sticky-phone]'));
        }
    }

    initProductSliderObject() {
        if (document.querySelector('[data-product-slider]')) {
            this.productSliderObject = new ProductSliderObject(document.querySelector('[data-product-slider]'));
        }
    }

    initProductSliderPictureObject() {
        const productSliderEl = document.querySelector('[data-product-slider]');
        const productSliderPicturesEl = document.querySelector('[data-product-slider-pictures]');
        if (productSliderEl && productSliderPicturesEl) {
            this.productPictureSliderObject = new ProductPictureObject(productSliderEl, productSliderPicturesEl);
        }
    }

    initCaseSliderObject () {
        if (document.querySelector('[data-case-slider-contrainer]')) {
            this.caseSliderObject = new CaseSliderObject(document.querySelector('[data-case-slider-contrainer]'));
        }
    }

    destroyObjects() {
        this.heroObject.clearStyles();
        this.heroObject = null;

        this.phoneObject = null;

        this.imageObjects.forEach((slide) => {
            slide.clearStyles();
        });
        this.imageObjects = [];

        this.productSliderObject.clearStyles();
        this.productSliderObject = null;

        this.productPictureSliderObject.clearStyles();
        this.productPictureSliderObject = null;

        this.caseSliderObject.clearStyles();
        this.caseSliderObject = null;
    }

    eventListeners() {
        document.addEventListener('scroll', () => {
            this.offsetY = window.scrollY;
        });

        window.addEventListener('resize', () => {
            if (window.innerWidth > this.maxMediumWidth) {
                this.initObjects();
            } else {
                this.destroyObjects();
            }
        });
    }

    update() {
        if (this.heroObject) {
            this.heroObject.update(this.offsetY);
        }

        if (this.phoneObject) {
            this.phoneObject.update(this.offsetY);
        }

        if (this.productSliderObject) {
            this.productSliderObject.update();
        }

        if (this.caseSliderObject) {
            this.caseSliderObject.update();
        }

        if (this.productPictureSliderObject) {
            this.productPictureSliderObject.update();
        }

        if (this.imageObjects) {
            this.updateImageObjects();
        }

        window.requestAnimationFrame(this.update.bind(this));
    }

    updateImageObjects() {
      this.imageObjects.forEach((imageObject) => {
        imageObject.update();
      });
    }
}

onDomReady(() => {
    const parallax = new Parallax();
});