import {onDomChanges} from "../../components/dynamic/observer";

function initYandexCaptcha() {

  if (!document.querySelector('[data-captcha-key]')) {
    console.debug('Yandex captcha key not found, check settings');
    return;
  }

  if (!window.smartCaptcha) {
    console.log('Yandex captcha not found');
    return;
  }

  const { captchaKey } = document.querySelector('[data-captcha-key]').dataset;
  const forms = document.querySelectorAll('form[data-yandex-captcha]');
  forms.forEach((formEl) => {
    // Два раза инициализировать не стоит
    if (formEl.dataset.captchaInitialized) {
      return;
    }

    formEl.dataset.captchaInitialized = 'true';
    let containerEl = formEl.querySelector('[data-captcha-container]');
    if (!containerEl) {
      containerEl = document.createElement('div');
      containerEl.setAttribute('id', 'captcha-container-' + randomId());
      containerEl.dataset.captchaContainer = 'true';
      const targetEl = formEl.querySelector('[type="submit"]');
      targetEl.parentNode.insertBefore(containerEl, targetEl);
    }


    // Инициализируем капчу
    const widgetId = window.smartCaptcha.render(containerEl.id, {
      sitekey: captchaKey,
      invisible: true,
      hideShield: true,
      callback: (token) => {
        // Генерируем событие успешной проверки
        const { containerId } = containerEl.dataset;
        document.dispatchEvent(new CustomEvent(`captchaSuccess-${containerId}`, { detail: token }));
      },
    });
    containerEl.dataset.containerId = widgetId;
  });
}

function randomId() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

// Инициализация в трех случаях: при загрузке страницы / яндекс-капчи / при ajax-обновлении страницы
onDomChanges(initYandexCaptcha);
window.onLoadCaptchaFunction = () => {
  initYandexCaptcha();
};