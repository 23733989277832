import {onDomReady} from "../../components/dynamic/observer";
import {scrollToTarget} from "../../components/scroll/scroll";

class Header {
    isOpened = false;
    scrollTop = 0;
    maxMediumWidth = 1179;
    lastWindowPosition = 0;
    constructor(selector) {
        this.header = selector;
        this.hamburger = document.querySelector('[data-hamburger]');
        this.hero = document.querySelector('[data-hero]');
        this.dropMenu = document.querySelector('[data-drop-menu]');
        this.logoLink = this.header.querySelector('[data-header-logo]');

        this.dropMenuLinks = this.dropMenu.querySelectorAll('[data-menu-link]')

        this.footer = document.querySelector('[data-footer]');

        const rootStyles = getComputedStyle(document.documentElement);
        this.headerHeight = parseInt(rootStyles.getPropertyValue('--header-height'), 10);

        this.bind();
        this.eventListeners();
    }

    bind() {
        if (this.hamburger) {
            this.hamburger.addEventListener('click', () => {
                if (!this.isOpened) {
                    this.open();
                } else {
                    this.close();
                }
            });
        }

        this.dropMenuLinks.forEach((item) => {
            const target = document.querySelector(item.getAttribute('href'));

            item.addEventListener('click', (e) => {
                e.preventDefault();

                this.close();

                setTimeout(() => {
                    scrollToTarget(target, this.headerHeight * -1);
                }, 400);
            });
        });

        if (this.logoLink) {
            this.logoLink.addEventListener('click', () => {
                scrollToTarget(this.hero, 0);
            });
        }
    }

    open() {
        this.isOpened = true;
        this.hamburger.classList.add('_opened');
        this.dropMenu.classList.add('_opened');

        if (window.innerWidth <= this.maxMediumWidth) {
            document.body.style.overflow = 'hidden';

            this.lastWindowPosition = window.scrollY;
            setTimeout(() => {
                document.querySelector('.layout').style.position = 'relative';
                document.querySelector('.layout').style.overflow = 'hidden';
                document.querySelector('.layout').style.height = 'calc(var(--vh, 1vh) * 100)';
            }, 400);
        }
    }

    close() {
        this.isOpened = false;
        this.hamburger.classList.add('_closing');
        this.hamburger.classList.remove('_opened');
        this.dropMenu.classList.remove('_opened');
        setTimeout(() => {
            this.hamburger.classList.remove('_closing');

            if (window.innerWidth <= this.maxMediumWidth) {
                document.body.style.overflow = '';
            }
        }, 300);

        if (window.innerWidth <= this.maxMediumWidth) {
            document.querySelector('.layout').style.position = '';
            document.querySelector('.layout').style.overflow = '';
            document.querySelector('.layout').style.height = '';
            window.scroll(0, this.lastWindowPosition);
        }
    }

    eventListeners() {
        window.addEventListener('scroll', () => {
            this.scrollTop = window.scrollY;

            const headerHeight = this.header.offsetHeight;
            if (this.hero) {
                const heroBottom = this.hero.offsetTop + this.hero.offsetHeight;
                if (this.scrollTop + headerHeight > heroBottom) {
                    this.header.classList.add('_sticky');
                } else {
                    this.header.classList.remove('_sticky');
                }
            }

            const footerBounds = this.footer.getBoundingClientRect();
            if (footerBounds.top <= this.headerHeight) {
                this.header.classList.add('_hidden');
            } else {
                this.header.classList.remove('_hidden');
            }
        });
    }
}

onDomReady(() => {
    const element = document.querySelector('[data-header]');
    new Header(element);
});