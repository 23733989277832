import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

function init() {
    const items = document.querySelectorAll('[data-autoresize]:not([data-initialized])');
    items.forEach(function (element) {
        element.dataset.initialized = 'true';

        element.style.boxSizing = 'border-box';
        const offset = element.offsetHeight - element.clientHeight;
        element.addEventListener('input', function (event) {
            event.target.style.height = 'auto';
            event.target.style.height = event.target.scrollHeight + offset + 'px';
        });
        element.removeAttribute('data-autoresize');
    });
}

onDomReady(() => init());
onDomChanges(() => init());