import RSlider from "../../components/rslider/rslider";
import {onDomReady} from "../../components/dynamic/observer";

onDomReady(() => {
    const rsliderElement = document.querySelector('[data-rslider="hero-slider"]');
    if (!rsliderElement) {
        return;
    }

    const setPictureWrapWidth = () => {
        document.querySelectorAll('.hero-slider__picture-wrap').forEach((pictureWrapEl) => {
            pictureWrapEl.style.width = `${document.body.clientWidth}px`;
        });
    };
    setPictureWrapWidth();
    window.addEventListener('resize', () => setPictureWrapWidth());

    const descriptionContainer = document.querySelector('[data-rslider-friends="description"]');
    if (descriptionContainer) {
        let maxDescriptionHeight = 0;
        descriptionContainer.querySelectorAll('li')
            .forEach((item) => {
                if (item.offsetHeight > maxDescriptionHeight) {
                    maxDescriptionHeight = item.offsetHeight;
                }
            });
        descriptionContainer.style.height = `${maxDescriptionHeight}px`;
    }

    const slider = new RSlider(
        rsliderElement,
        {
            onStart: ({ slideIn, slideFriendsIn }) => {
                setTimeout(() => {
                    slideFriendsIn.forEach((friendIn) => {
                        friendIn.classList.add('_active');
                    })
                    slideIn.classList.add('_active');
                }, 3100);
            },
            timeline: [
                RSlider.action({
                    // К слайдам докидывается:
                    // К появляющемуся: --rslider-first-act-in-progress: 0.2;
                    // К исчезающему: --rslider-first-act-out-progress: 0.2;
                    // easing ниже настраивается
                    name: 'next-act',
                    duration: 1500,
                    delay: 0,
                    // easeInOutCubic прогресс для "приходящего" слайда
                    // easingIn: (x) => x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2,
                    // linear прогресс для "уходящего" слайда
                    // easingOut: (x) => x,
                    onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                        slideIn.classList.add('_active');
                        slideOut.classList.remove('_active');

                        slideIn.classList.add('_in-next-act');
                        slideOut.classList.add('_out-next-act');

                        slideFriendsIn.forEach((friendIn) => {
                            friendIn.classList.add('_active');
                            friendIn.classList.add('_in-next-act');
                        })

                        setTimeout(() => {
                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.remove('_in-next-act');
                            })
                        }, 300);

                        slideFriendsOut.forEach((friendOut) => {
                            friendOut.classList.remove('_active');
                            friendOut.classList.add('_out-next-act');
                        })
                    },
                    onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                        slideIn.classList.remove('_in-next-act');
                        slideOut.classList.remove('_out-next-act');

                        slideFriendsOut.forEach((friendOut) => {
                            friendOut.classList.remove('_out-next-act');
                        })
                    }
                }),
            ],
            timelineReverse: [
                RSlider.action({
                    name: 'prev-act',
                    duration: 1500,
                    delay: 0,
                    onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                        slideIn.classList.add('_active');
                        slideOut.classList.remove('_active');

                        slideIn.classList.add('_in-prev-act');
                        slideOut.classList.add('_out-prev-act');

                        slideFriendsIn.forEach((friendIn) => {
                            friendIn.classList.add('_active');
                            friendIn.classList.add('_in-prev-act');
                        })

                        setTimeout(() => {
                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.remove('_in-prev-act');
                            })
                        }, 300);

                        slideFriendsOut.forEach((friendOut) => {
                            friendOut.classList.remove('_active');
                            friendOut.classList.add('_out-prev-act');
                        })
                    },
                    onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                        slideIn.classList.remove('_in-prev-act');
                        slideOut.classList.remove('_out-prev-act');

                        slideFriendsOut.forEach((friendOut) => {
                            friendOut.classList.remove('_out-prev-act');
                        })
                    }
                }),
            ]
        });
})