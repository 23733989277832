export default class Office {
    constructor(listItemElement) {
        this.settings = Object.assign({
            lat: 0,
            lng: 0,
            type: '',
            zoom: undefined,
        }, listItemElement.dataset);

        this.placemark = undefined;
    }

    getLat() {
        return parseFloat(this.settings.lat);
    }

    getLng() {
        return parseFloat(this.settings.lng);
    }

    setPlacemark(placemark) {
        this.placemark = placemark;
    }

    getPlacemark() {
        return this.placemark;
    }
}