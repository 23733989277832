import {onDomReady} from "../../components/dynamic/observer";

onDomReady(() => {
    if ("IntersectionObserver" in window) {
        const lazyVideos = [].slice.call(document.querySelectorAll('[data-lazy-video]:not([data-initialized="true"])'));

        const lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(video) {
                if (video.isIntersecting) {
                    for (const source in video.target.children) {
                        const videoSource = video.target.children[source];
                        if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                            videoSource.src = videoSource.dataset.src;
                        }
                    }

                    video.target.load();
                    video.target.dataset.initialized = "true";
                    lazyVideoObserver.unobserve(video.target);
                }
            });
        });

        lazyVideos.forEach(function(lazyVideo) {
            lazyVideoObserver.observe(lazyVideo);
        });
    }
});