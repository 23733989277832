import {onDomReady} from "../../components/dynamic/observer";

class ModalContent {
    isOpened = false;
    lastWindowPosition = 0;
    constructor(element) {
        this.modal = element;
        this.layout = this.modal.querySelector('.modal-content__layout');
        this.content = this.modal.querySelector('.modal-content__data-inner div');
        this.openButtons = document.querySelectorAll(`[data-modal-content-open="${this.modal.dataset.modalContent}"]`);
        this.closeButtons = document.querySelectorAll(`[data-modal-content-close="${this.modal.dataset.modalContent}"]`);

        const rootStyles = getComputedStyle(document.documentElement);
        this.gridSpacer = parseInt(rootStyles.getPropertyValue('--grid-spacer'), 10);

        this.evensListeners();
    }

    evensListeners() {
        this.openButtons.forEach((item) => {
            item.addEventListener('click', () => {
                this.dispatchOpenEvent(item);
                this.openModal();
            });
        });

        this.closeButtons.forEach((item) => {
            item.addEventListener('click', () => {
                this.closeModal();
            });
        });

        this.modal.addEventListener('click', (e) => {
            if (this.isOpened && e.target === this.layout) {
                this.closeModal();
            }
        });
    }

    openModal() {
        this.isOpened = true;
        this.modal.classList.add('_opened');
        this.content.classList.add('_opened');

        let bodyWidth = document.body.offsetWidth;
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = (document.body.offsetWidth - bodyWidth) + 'px';
        document.querySelector('[data-header]').style.maxWidth = `calc(100% - ${document.body.offsetWidth - bodyWidth}px)`;
        document.querySelector('[data-sticky-phone]').style.left = `calc(100% - (60px + var(--grid-spacer) + ${document.body.offsetWidth - bodyWidth}px))`;

        if (window.innerWidth < 1180) {
            this.lastWindowPosition = window.scrollY;

            setTimeout(() => {
                document.querySelector('.layout').style.position = 'relative';
                document.querySelector('.layout').style.overflow = 'hidden';
                document.querySelector('.layout').style.height = 'calc(var(--vh, 1vh) * 100)';
            }, 400);
        }
    }

    closeModal() {
        this.isOpened = false;
        this.modal.classList.remove('_opened');
        this.content.classList.remove('_opened');

        setTimeout(() => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
            document.querySelector('[data-header]').style.maxWidth = '';
            document.querySelector('[data-sticky-phone]').style.left = '';
        }, 600);

        if (window.innerWidth < 1180) {
            document.querySelector('.layout').style.position = '';
            document.querySelector('.layout').style.overflow = '';
            document.querySelector('.layout').style.height = '';
            window.scroll(0, this.lastWindowPosition);
        }
    }

    dispatchOpenEvent(item) {
        this.modal.dispatchEvent(new CustomEvent('ModalContentOpened', {
            detail: {
                slideId: parseInt(item.dataset.slideId, 10)
            }
        }));
    }
}

onDomReady(() => {
    const elements = document.querySelectorAll('[data-modal-content]:not([data-initialized="true"])');
    elements.forEach((element) => {
        element.dataset.initialized = 'true';
        const modalContent = new ModalContent(element);
    });
});