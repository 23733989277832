import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper, Pagination} from 'swiper';

function init() {
    const sliderEl = document.querySelector('[data-reviews-slider]');
    if (!sliderEl) {
        return;
    }

    const prevEl = document.querySelector('[data-reviews-slider-prev]');
    const nextEl = document.querySelector('[data-reviews-slider-next]');
    const paginationEl = document.querySelector('[data-reviews-slider-pagination]');

    const slider = new Swiper(sliderEl, {
        modules: [Navigation, Pagination],
        slidesPerView: 2,
        freeMode: true,
        grabCursor: true,
        loop: true,
        navigation: {
            prevEl: prevEl,
            nextEl: nextEl,
        },
        pagination: {
            type: 'bullets',
            el: paginationEl
        },
        breakpoints: {
            0: {
                spaceBetween: 10,
                slidesPerView: 1,
            },
            768: {
                spaceBetween: 20,
            },
            1180: {
                spaceBetween: 30,
            },
        }
    });
}

onDomReady(init);