// сорян за это дерьмо, я приберусь потом.
import {onDomReady} from "../../components/dynamic/observer";

class Advantages {
    constructor(selector) {
        this.container = selector;
        this.list = this.container.querySelector('[data-advantage-list]');
        this.items = this.list.querySelectorAll('[data-advantage-item]');
        if (!this.items) {
            return;
        }

        this.initRootStyles();
        this.setInitStyles();

        this.update();
    }

    initRootStyles() {
        const rootStyles = getComputedStyle(document.documentElement);
        this.headerHeight = parseInt(rootStyles.getPropertyValue('--header-height'), 10);
        this.headingHeight = this.items[0].querySelector('.advantages-slider__slide-info').offsetHeight;
    }

    setInitStyles() {
        let maxHeight = 0;
        this.items.forEach((item, index) => {
            item.style.position = 'sticky';
            item.style.top = `${this.headerHeight + (this.headingHeight * index)}px`;

            if (item.offsetHeight > maxHeight) {
                maxHeight = item.offsetHeight;
            }
        });

        const lastItemInitHeight = this.items[this.items.length-1].offsetHeight;
        const diffHeight = maxHeight - lastItemInitHeight;

        this.list.style.marginBottom = `${Math.abs(diffHeight) * -1}px`;
        this.items[this.items.length-1].style.height = `${maxHeight}px`;

        const listDefaultHeight = this.list.offsetHeight;
        const listOffset = Math.ceil(diffHeight + maxHeight/this.items.length + listDefaultHeight);
        this.list.style.height = `${listOffset}px`;
    }

    update() {
        this.items.forEach((item, index) => {
            const itemBounds = item.getBoundingClientRect();
            const itemBoundsTop = itemBounds.top;
            const itemBoundsBottom = itemBoundsTop + item.offsetHeight;
            const textWrap = item.querySelector('.advantages-slider__text-wrap-inner');

            if (itemBoundsTop <= this.headerHeight + (this.headingHeight * index)) {
                if (itemBoundsBottom - window.innerHeight > 0) {
                    if (this.items[index + 1]) {
                        const offset = this.items[index + 1].getBoundingClientRect().top - itemBoundsBottom;
                        if (Math.abs(offset) <= itemBoundsBottom - window.innerHeight) {
                            textWrap.style.transform = `translate3d(0, ${offset}px, 0)`;
                        }
                    }
                }
            } else {
                textWrap.style.transform = '';
            }
        });

        window.requestAnimationFrame(this.update.bind(this));
    }
}

onDomReady(() => {
    if (window.innerWidth >= 1180) {
        if (document.querySelector('[data-advantage-container]')) {
            const advantages = new Advantages(document.querySelector('[data-advantage-container]'));
        }
    }
})